import { Records, State } from '@dispatcher-stratus/ecks/dist/types'
import Ecks from '@dispatcher-stratus/ecks'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import XRecord from '@dispatcher-stratus/ecks/dist/std/record'

export function objectToState(
  o: Record<string, any>,
  records: Records = new Map()
): State {
  let m = new Map()
  for (let k of Object.keys(o)) {
    if (o[k] instanceof Object && !Array.isArray(o[k])) {
      m.set(k, Ecks.fromJs(objectToMap(o[k])))
    } else if (Array.isArray(o[k])) {
      m.set(k, Ecks.fromJs(o[k].map((el: any) => objectToMap(el))))
    } else {
      m.set(k, Ecks.fromJs(o[k]))
    }
  }

  return {
    environment: m,
    records,
  }
}

export function objectToMap(o: Record<string, any>) {
  if (typeof o !== 'object') return o
  let m = new Map()
  for (let k of Object.keys(o)) {
    if (o[k] instanceof Object && !Array.isArray(o[k])) {
      m.set(k, objectToMap(o[k]))
    } else if (Array.isArray(o[k])) {
      m.set(k, o[k].map(objectToMap))
    } else {
      m.set(k, o[k])
    }
  }
  return m
}

export function objectToEcksMap(
  o: Record<string, any>
): Map<string, TypedValue> {
  let m = new Map()
  for (let k of Object.keys(o)) {
    if (o[k] instanceof Object && !Array.isArray(o[k])) {
      m.set(k, Ecks.fromJs(objectToMap(o[k])))
    } else if (Array.isArray(o[k])) {
      m.set(k, Ecks.fromJs(o[k].map((el: any) => objectToMap(el))))
    } else {
      m.set(k, Ecks.fromJs(o[k]))
    }
  }
  return m
}

export const findExpressions = (str: string) => {
  const regex = /\{+\s*([^{}]+)\s*\}+/gm
  const matches =
    str.match(regex)?.map((val) => val.substring(1, val.length - 1)) || []
  return matches.filter((el) => {
    return (
      el.includes(':') ||
      el.includes('.') ||
      el.includes('[') ||
      el.includes('"')
    )
  })
}

export function convertTypedToEcksJson(typedValue: TypedValue): any {
  if (typeof typedValue.__value !== 'object') {
    return typedValue.__value
  }
  const obj: any = {
    kind: typedValue.kind,
  }
  if (typedValue instanceof XRecord) {
    obj.name = typedValue.__name
    const value: any = {}
    typedValue.__value.forEach((v, k) => {
      value[k] = convertTypedToEcksJson(v)
    })
    obj.value = value
  } else {
    obj.value = typedValue.__value
  }
  return obj
}

export class MetadataProcessError extends Error {
  metadataKey: string = ''

  constructor(metadataKey: string, message: string) {
    super()
    this.metadataKey = metadataKey
    this.message = message
  }
}
