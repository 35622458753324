import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XString from '@dispatcher-stratus/ecks/dist/std/string'
import XInteger from '@dispatcher-stratus/ecks/dist/std/integer'
import XDate from '@dispatcher-stratus/ecks/dist/std/date'

export class Process extends XRecord {
  private id?: XString
  private created?: XDate
  private name?: XString
  private startTime?: XDate
  private status?: XString
  private duration?: XInteger

  constructor(value: Map<string, TypedValue>, state?: State) {
    const defaultState: State = { environment: new Map(), records: new Map() }
    const resolvedState = state || defaultState
    super(value, resolvedState)

    const id = value.get('id')
    if (id !== undefined && !(id instanceof XString)) {
      throw new TypeError()
    }
    const created = value.get('created')
    if (created !== undefined && !(created instanceof XDate)) {
      throw new TypeError()
    }
    const name = value.get('name')
    if (name !== undefined && !(name instanceof XString)) {
      throw new TypeError()
    }
    const startTime = value.get('startTime')
    if (startTime !== undefined && !(startTime instanceof XDate)) {
      throw new TypeError()
    }
    const status = value.get('status')
    if (status !== undefined && !(status instanceof XString)) {
      throw new TypeError()
    }
    const duration = value.get('duration')
    if (duration !== undefined && !(duration instanceof XInteger)) {
      throw new TypeError()
    }

    this.id = id
    this.created = created
    this.name = name
    this.startTime = startTime
    this.status = status
    this.duration = duration
  }
  __new(value: Map<string, TypedValue>): XRecord {
    return new Process(value, this.__state)
  }
}
