import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XString from '@dispatcher-stratus/ecks/dist/std/string'
import XInteger from '@dispatcher-stratus/ecks/dist/std/integer'
import XMap from '@dispatcher-stratus/ecks/dist/std/map'

export class File extends XRecord {
  private id?: XString
  private fullName?: XString
  private name?: XString
  private ext?: XString
  private mime?: XString
  private size?: XInteger
  private ocr?: XMap
  private form?: XMap
  private odbc?: XMap
  private annotate?: XMap
  private dropboxin?: XMap
  private scripting?: XMap
  private msexchangein?: XMap

  constructor(value: Map<string, TypedValue>, state?: State) {
    const defaultState: State = { environment: new Map(), records: new Map() }
    const resolvedState = state || defaultState
    super(value, resolvedState)

    const id = value.get('id')
    if (id !== undefined && !(id instanceof XString)) {
      throw new TypeError()
    }
    const fullName = value.get('fullName')
    if (fullName !== undefined && !(fullName instanceof XString)) {
      throw new TypeError()
    }
    const name = value.get('name')
    if (name !== undefined && !(name instanceof XString)) {
      throw new TypeError()
    }
    const ext = value.get('ext')
    if (ext !== undefined && !(ext instanceof XString)) {
      throw new TypeError()
    }
    const mime = value.get('mime')
    if (mime !== undefined && !(mime instanceof XString)) {
      throw new TypeError()
    }
    const size = value.get('size')
    if (size !== undefined && !(size instanceof XInteger)) {
      throw new TypeError()
    }
    const ocr = value.get('ocr')
    if (ocr !== undefined && !(ocr instanceof XMap)) {
      throw new TypeError()
    }
    const form = value.get('form')
    if (form !== undefined && !(form instanceof XMap)) {
      throw new TypeError()
    }
    const odbc = value.get('odbc')
    if (odbc && !(odbc instanceof XMap)) {
      throw new TypeError()
    }

    const annotate = value.get('annotate')
    if (annotate && !(annotate instanceof XMap)) {
      throw new TypeError()
    }

    const dropboxin = value.get('dropboxin')
    if (dropboxin && !(dropboxin instanceof XMap)) {
      throw new TypeError()
    }

    const scripting = value.get('scripting')
    if (scripting && !(scripting instanceof XMap)) {
      throw new TypeError()
    }

    const msexchangein = value.get('msexchangein')
    if (msexchangein && !(msexchangein instanceof XMap)) {
      throw new TypeError()
    }

    this.id = id
    this.fullName = fullName
    this.name = name
    this.ext = ext
    this.mime = mime
    this.size = size
    this.ocr = ocr
    this.form = form
    this.odbc = odbc
    this.annotate = annotate
    this.dropboxin = dropboxin
    this.scripting = scripting
    this.msexchangein = msexchangein
  }
  __new(value: Map<string, TypedValue>): XRecord {
    return new File(value, this.__state)
  }
}
