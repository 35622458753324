import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XString from '@dispatcher-stratus/ecks/dist/std/string'

export class InternalJobTicketForm extends XRecord {
  private formUrl: XString
  private formId: XString
  private formTitle: XString

  constructor(value: Map<string, XString>, state?: State) {
    const defaultState: State = { environment: new Map(), records: new Map() }
    const resolvedState = state || defaultState
    super(value, resolvedState)

    const url = value.get('formUrl')
    if (url === undefined || !(url instanceof XString)) {
      throw new TypeError()
    }
    const id = value.get('formId')
    if (id === undefined || !(id instanceof XString)) {
      throw new TypeError()
    }
    const title = value.get('formTitle')
    if (title === undefined || !(title instanceof XString)) {
      throw new TypeError()
    }

    this.formUrl = url
    this.formId = id
    this.formTitle = title
  }
  __new(value: Map<string, XString>): XRecord {
    return new InternalJobTicketForm(value, this.__state)
  }
}
