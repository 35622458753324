import { findExpressions, objectToMap } from './utils'
import Ecks from '@dispatcher-stratus/ecks'
import fetch from 'node-fetch'
import { GetMetadataOptions } from './types'
import { Process, User, Workflow, File } from './records'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import { Form } from './records/Form'
import { Odbc } from './records/Odbc'
import { Annotate } from './records/Annotate'
import { Dropboxin } from './records/Dropboxin'
import { Scripting } from './records/Scripting'
import { Msexchangein } from './records/Msexchangein'
import { FormDataCollection } from './records/FormDataCollection'
import { InternalJobTicketForm } from './records/InternalJobTicketForm'
export * from './utils'
export * from './types'

export const expandString = (input: string, state: State) => {
  const matches = findExpressions(input)
  const ecksExpressions = matches.map((match) => {
    try {
      const evaludatedMatch = Ecks.eval(match.replace(/:\s*/, '.'), state)
      if (!evaludatedMatch) {
        console.error(`No evaluated match for ${match}`)
        return ''
      }
      return evaludatedMatch
    } catch (err: any) {
      console.error(`Error evaluating ${match}`, err)
      return ''
      // throw new MetadataProcessError(match, err.message);
    }
  })
  const evaluatedExpressions = ecksExpressions.map((ecksExpr) => {
    if (ecksExpr) return Ecks.toJs(ecksExpr)
    return ''
  })
  let newStr = input
  evaluatedExpressions.forEach((val, i) => {
    newStr = newStr.replace(`{${matches[i]}}`, `${val}`)
    newStr = newStr.replace('[object Map]', 'undefined')
  })
  return newStr
}
export const getMetadata = async (options: GetMetadataOptions) => {
  const records = new Map<string, any>([
    ['user', User],
    ['workflow', Workflow],
    ['process', Process],
    ['file', File],
    ['form', Form],
    ['formdatacollection', FormDataCollection],
    ['intneraljobticketform', InternalJobTicketForm],
    ['odbc', Odbc],
    ['annotate', Annotate],
    ['dropboxin', Dropboxin],
    ['scripting', Scripting],
    ['msexchangein', Msexchangein],
  ])
  const state = { environment: new Map(), records }
  if (options.userId) {
    const resUser = await fetch(
      `https://${options.region || 'us-east-1'}.metadata-api.${
        options.domain || 'stratus.lol'
      }/api/v1/user/${options.userId}`
    )
      .then((res) => res.json())
      .then((res) => {
        return {
          kind: 'record',
          name: 'user',
          value: {
            ...res?.data,
            name: res?.data?.email?.split('@')?.[0] || res?.data?.name,
          },
        }
      })
      .then((res) => Ecks.fromJs(res, state))
      .catch((err) => {
        console.error(err)
        return undefined
      })
    state.environment.set('user', resUser)
  }
  if (options.workflowId) {
    const resWorkflow = await fetch(
      `https://${options.region || 'us-east-1'}.metadata-api.${
        options.domain || 'stratus.lol'
      }/api/v1/workflow/${options.workflowId}/${options.workflowId}`
    )
      .then((res) => res.json())
      .then((res) => {
        return {
          kind: 'record',
          name: 'workflow',
          value: {
            ...res?.data,
            modified: res?.data?.modified
              ? { kind: 'date', value: res?.data?.modified }
              : undefined,
          },
        }
      })
      .then((res) => Ecks.fromJs(res, state))
      .catch((err) => {
        console.error(err)
        return undefined
      })
    state.environment.set('workflow', resWorkflow)
  }
  if (options.processId && options.workflowId) {
    const resProcess = await fetch(
      `https://${options.region || 'us-east-1'}.metadata-api.${
        options.domain || 'stratus.lol'
      }/api/v1/process/${options.workflowId}/${options.processId}`
    )
      .then((res) => res.json())
      .then((res) => {
        const process: {
          kind: string
          name: string
          value: Record<string, any>
        } = {
          kind: 'record',
          name: 'process',
          value: {},
        }

        // handle objects in process metadata record (caused issues in fromJS conversion when encountering objects as values)
        // see https://dev2.sec.kmbs.us/dispatcher-stratus/plugins/cloudfax/-/issues/10
        if (!res.data) return process
        for (const [key, value] of Object.entries(res.data)) {
          if (value && typeof value === 'object') {
            process.value[key] = objectToMap(value)
          } else {
            process.value[key] = value
          }
        }

        return process
      })
      .then((res) => {
        return {
          process: Ecks.fromJs(res, state),
          form: Ecks.fromJs(res.value.form, state),
          internaljobticketform: Ecks.fromJs(
            res.value.internaljobticketform,
            state
          ),
          formdatacollection: Ecks.fromJs(res.value.formdatacollection, state),
        }
      })
      .catch((err) => {
        console.error('error from getMetadata process fromJs', err)
        return undefined
      })
    if (resProcess?.process)
      state.environment.set('process', resProcess.process)
    if (resProcess?.form) state.environment.set('form', resProcess.form)
    if (resProcess?.formdatacollection)
      state.environment.set('formdatacollection', resProcess.formdatacollection)
    if (resProcess?.internaljobticketform)
      state.environment.set(
        'internaljobticketform',
        resProcess.internaljobticketform
      )
  }
  if (options.fileId && options.processId) {
    const resFile = await fetch(
      `https://${options.region || 'us-east-1'}.metadata-api.${
        options.domain || 'stratus.lol'
      }/api/v1/file/${options.processId}/${encodeURIComponent(options.fileId)}`
    )
      .then((res) => res.json())
      .then((res) => {
        const file: {
          kind: string
          name: string
          value: Record<string, any>
        } = {
          kind: 'record',
          name: 'file',
          value: {},
        }

        if (!res.data) return file
        for (const [key, value] of Object.entries(res.data)) {
          if (value && typeof value === 'object') {
            file.value[key] = objectToMap(value)
          } else {
            file.value[key] = value
          }
        }

        return file
      })
      .then((res) => {
        return {
          file: Ecks.fromJs(res, state),
          odbc: Ecks.fromJs(res.value.odbc, state),
          annotate: Ecks.fromJs(res.value.annotate, state),
          dropboxin: Ecks.fromJs(res.value.dropboxin, state),
          scripting: Ecks.fromJs(res.value.scripting, state),
          msexchangein: Ecks.fromJs(res.value.msexchangein, state),
        }
      })
      .catch((err) => {
        console.error('error from getMetadata file fromJs', err)
        return undefined
      })
    if (resFile?.file) state.environment.set('file', resFile.file)
    if (resFile?.odbc) state.environment.set('odbc', resFile.odbc)
    if (resFile?.annotate) state.environment.set('annotate', resFile.annotate)
    if (resFile?.dropboxin)
      state.environment.set('dropboxin', resFile.dropboxin)
    if (resFile?.scripting)
      state.environment.set('scripting', resFile.scripting)
    if (resFile?.msexchangein)
      state.environment.set('msexchangein', resFile.msexchangein)
  }
  return state
}
